/* --main-theme-color: #222831;   */
/* --gray-backgroud-color:  #eeeeee; */

:root {
  --primary-color: #1677ff;
  --main-theme-color: #141a16;
  --gray-color: #727272;
  --gray-backgroud-color: rgb(22 24 35 / 5%);
  --gray-backgroud-color-hover: #f6f6f6;
  --white-color: rgb(255, 255, 255);
  --dark-color: rgb(34, 34, 34);

  /* font-size */
  --small-fontsize: 0.75rem; /* 12px */
  --middle-fontsize: 0.875rem; /* 14px */
  --main-fontsize: 1rem; /* 16px */
  --large-fontsize: 1.125rem; /* 18px */
}

/* Common */
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--main-theme-color) !important;
  font-weight: bold !important;
}

.ant-tabs .ant-tabs-ink-bar {
  background: var(--main-theme-color) !important;
}

.ant-tabs .ant-tabs-tab:hover {
  color: var(--main-theme-color) !important;
}

.ant-skeleton .ant-skeleton-image .anticon {
  display: none;
}

@media (max-width: 768px) {
  html {
    font-size: 15px !important; /* 1rem = 12px */
  }

  body {
    margin: 0;
  }

  .ant-drawer-header {
    padding: 0.5rem !important;
  }

  .ant-drawer-body {
    padding: 0 1rem !important;
  }

  .ant-modal .ant-modal-content {
    padding: 1rem !important;
  }

  .vfra-container {
    padding: 0;
  }

  .vfra-container .ant-breadcrumb {
    margin-left: 0px !important;
  }

  .ant-tabs-top > .ant-tabs-nav {
    margin-bottom: 0.3rem;
  }
}

@media (min-width: 768px) {
  html {
    /* font-size: 16px; */
    font-size: 2vh;
  }
}

@media (min-width: 1280px) {
  html {
    /* font-size: 16px; */
    font-size: 2.1vh;
  }
}

@media (min-width: 1681px) {
  html {
    /* font-size: 16px; */
    font-size: 2.3vh;
  }

  .ant-layout-content {
    padding: 0 15vw;
    padding-top: 3rem;
  }
}

@media (min-width: 2500px) {
  html {
    /* font-size: 18px; */
    font-size: 2.4vh;
  }

  .ant-layout-content {
    padding: 0 20vw;
    padding-top: 3rem;
  }
}

/* COMMON CSS */
.vfra-scroll {
  height: 100%;
  overflow-y: auto;
}

.one-line-ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.two-line-ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.three-line-ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.ant-modal-root {
  position: absolute;
}

.ant-input-affix-wrapper-lg {
  border-radius: 1.5rem !important;
}
