.vfra-top-reviewer-tag {
  margin-top: 0.75rem;
  border-radius: 0.75rem;
  padding: 3px 12px;
  font-size: 0.7rem;
  font-weight: 500;
}

.vfra-top-reviewer-featured-post {
  font-size: 0.7rem;
  font-weight: 300;
}

.vfra-top-reviewer-featured-post:hover {
  cursor: pointer;
  text-decoration-line: underline;
}
