.vfra-sound-item {
  position: relative;
  border: 1px solid #f0f0f0;
  border-radius: 6px;
  padding: 6px;
  margin: 6px 0px;
}

.vfra-sound-item:hover {
  cursor: pointer;
  border-color: #1677ff;
  background-color: #f5f5f5fe;
}

.vfra-sound-item img {
  border-radius: 50%;
}

.active {
  border-color: #1677ff;
}

.vfra-sound-item-active-icon {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  transform: translate(50%, -50%);
  color: #1677ff;
  display: flex;
  background-color: white;
}
