.vfra-multisearch-tabs .ant-tabs-nav-list {
  gap: 1.5rem !important;
}

.vfra-multisearch-tabs .ant-tabs-tab .ant-tabs-tab-btn {
  font-weight: 500 !important;
}

.vfra-multisearch-tabs .ant-tabs-tab {
  font-size: 0.9rem !important;
}

.vfra-multisearch-tabs .vfra-home-tab-content {
  height: 90vh;
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 5vh;
}

@media (max-width: 768px) {
  .vfra-search-container {
    padding: 1rem 2rem;
  }

  .vfra-multisearch-tabs .ant-tabs-content-holder {
    padding-top: 0.5rem;
  }

  .vfra-search {
    width: 100%;
    font-size: 0.75rem;
  }

  .vfra-multisearch-tabs .ant-tabs-tab {
    padding-bottom: 0.4rem !important;
    margin: 0 0 0 0 !important;
    padding-top: 0.5rem;
  }

  .vfra-multisearch-tabs .ant-tabs-nav-list {
    gap: 1rem !important;
  }

  .vfra-multisearch-tabs-home-mobile .ant-tabs-nav {
    padding: 1vh;
    top: 0;
  }
}
