/* ==== Common ==== */
.vfra-header-sub-menu-item-btn {
  font-weight: 500;
  font-size: 0.8rem;
  color: var(--gray-color);
}

.vfra-header-sub-menu-item-btn:hover {
  cursor: pointer;
  text-decoration-line: underline;
}

/* ==== header mobile ==== */
.vfra-menu-active {
  color: #1677ff;
}

.vfra-menu-item {
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
}

.text-bold-white {
  color: var(--white-color);
  font-weight: bold;
}

.vfra-search-main-mobile .ant-input-group-addon {
  border-radius: 16px 0 0 16px !important;
}

.vfra-search-main-mobile .ant-input-affix-wrapper {
  border-radius: 0 16px 16px 0 !important;
}

.vfra-search-history-item:hover {
  cursor: pointer;
}

/* ==== header web ==== */
.vfra-header-container .vfra-search-main {
  width: 80%;
}

.vfra-header-container .vfra-search-main .ant-input-affix-wrapper-lg {
  padding: 0.5rem 1rem !important;
  border-radius: 1.5rem 0 0 1.5rem !important;
  background-color: var(--gray-backgroud-color) !important;
  height: auto;
}

.vfra-header-container .vfra-search-main .ant-input-search-button {
  border-radius: 0 1.5rem 1.5rem 0 !important;
  background-color: var(--gray-backgroud-color) !important;
  padding: 0.5rem 1.5rem !important;
  height: auto;
}

.vfra-header-container .vfra-search-main .ant-input-search-button .anticon {
  font-size: 24px;
}

.vfra-header-container .vfra-search-main input::placeholder {
  color: var(--gray-color) !important;
}

.vfra-header-container .vfra-header-menu-btn {
  padding: 0.35rem 1rem;
  border-radius: 1rem;
  background-color: var(--gray-backgroud-color);
}

.vfra-header-container .vfra-header-menu-btn:hover {
  cursor: pointer;
  background-color: var(--gray-backgroud-color-hover);
}

.vfra-header-container .vfra-header-menu-btn span {
  font-size: 1rem;
  font-weight: 500;
  color: var(--main-theme-color);
}

.vfra-header-container .vfra-header-menu-btn-active {
  background-color: var(--main-theme-color);
}

.vfra-header-container .vfra-header-menu-btn-active:hover {
  background-color: var(--main-theme-color);
}

.vfra-header-container .vfra-header-menu-btn-active span {
  color: var(--gray-backgroud-color-hover);
}

.vfra-header-menu-item {
  padding: 0.5rem;
  border-radius: 0.5rem;
}

.vfra-header-menu-item:hover {
  cursor: pointer;
  background-color: var(--gray-backgroud-color-hover);
}

.vfra-header-menu-item span {
  font-weight: 500;
  font-size: 0.8rem;
}

.vfra-header-sub-menu-item {
  font-weight: 200;
  font-size: 0.7rem;
  color: var(--gray-color);
}

@media (max-width: 768px) {
  .vfra-header-container {
    background: transparent;
    position: fixed;
    z-index: 100;
    top: 0;
    right: 0;
    left: 0;
    padding: 0.5rem 0.8125rem;
  }

  .ant-drawer-header {
    padding: 0.25rem 0.8125rem !important;
    z-index: 10000;
    flex: none !important;
    height: 6vh;
  }

  .vfra-menu-item {
    cursor: pointer;
    font-size: 1rem;
  }

  .vfra-header-sub-menu-item {
    font-weight: 200;
    font-size: 0.9rem;
    color: var(--gray-color);
  }

  .vfra-header-sub-menu-item-btn {
    font-size: 0.9rem;
    color: var(--gray-color);
  }
}

@media (min-width: 768px) {
  .vfra-header-container {
    background: var(--white-color);
    position: fixed;
    z-index: 100;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    padding: 0 2rem;
    height: 8vh;
  }
}
