.vfra-create-post-container {
  border: 1px solid #f0f0f0;
  border-radius: 12px;
  padding: 18px;
  width: 100%;
}

.vfra-close-relate-perfumes-btn {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  transform: translateX(50%);
}

.vfra-selected-perfume-item {
  border: 1px solid #f0f0f0;
  border-radius: 6px;
  padding: 6px;
}

.vfra-remove-selected-perfume-btn {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  transform: translate(50%, -50%);
}

.vfra-create-post-container > textarea::placeholder {
  color: black !important;
  opacity: 1 !important;
}

.vfra-create-post-container .ant-input {
  padding: 0 !important;
}

/* handle sound */

.spinning {
  animation: spin 5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
