.vfra-popular-perfumes-parent-container {
  position: relative;
}

.vfra-popular-perfumes-prev-btn {
  position: absolute;
  left: -0.5rem;
  top: 50%;
  z-index: 90;
}

.vfra-popular-perfumes-next-btn {
  position: absolute;
  right: -0.5rem;
  top: 50%;
  z-index: 90;
}

.vfra-popular-perfumes-container {
  display: -webkit-inline-box;
  overflow: auto;
  scrollbar-width: none;
  padding: 1rem 0;
}

.vfra-popular-perfumes-container-before::before {
  content: "";
  width: 3rem;
  height: 80%;
  left: 0px;
  bottom: 0;
  position: absolute;
  z-index: 50;
  background: linear-gradient(90deg, rgb(255, 255, 255), rgba(255, 255, 255, 0.05));
}

.vfra-popular-perfumes-container-after::after {
  content: "";
  width: 3rem;
  height: 80%;
  right: 0px;
  bottom: 0;
  position: absolute;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.05), rgb(255, 255, 255));
}

@media (max-width: 768px) {
  .vfra-popular-perfumes-container-before::before {
    height: 90%;
    width: 1rem;
  }

  .vfra-popular-perfumes-container-after::after {
    height: 90%;
    width: 1rem;
  }
}
