.vfra-remove-image-btn {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  transform: translate(0, -50%);
}

.vfra-image-dragger-container .ant-upload-drag {
  height: 420px !important;
}

.active {
  border-color: #1677ff;
}

@media (max-width: 768px) {
  .vfra-image-dragger-container .ant-upload-drag {
    height: 300px !important;
  }
}
