.vfra-explore-card-popular {
  border: 1px solid #e2e2e2;
  border-radius: 1rem;
  padding: 0 1rem;
  padding-bottom: 1rem;
  margin-right: 1rem;
}

.vfra-explore-card-popular-img {
  transform: translateY(-2rem);
  height: 25vh;
}

.vfra-explore-card-popular-title {
  margin: -1rem 0 0.5rem 0;
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .vfra-explore-card-popular {
    width: 50vw !important;
  }
}

@media (min-width: 768px) {
  .vfra-explore-card-popular {
    width: 18vw;
  }
}

@media (min-width: 1681px) {
  .vfra-explore-card-popular {
    width: 15vw;
  }
}

@media (min-width: 2500px) {
  .vfra-explore-card-popular {
    width: 12vw;
  }
}
