.vfra-close-upload-btn {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  transform: translate(50%, -50%);
}

.vfra-remove-image-btn {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  transform: translate(0, -50%);
}

.ant-upload-drag {
  height: 130px !important;
}
