.Vfra-top-reviewers-parent-container {
  position: relative;
}

.vfra-top-reviewers-prev-btn {
  position: absolute;
  left: -0.5rem;
  top: 50%;
  z-index: 90;
}

.vfra-top-reviewers-next-btn {
  position: absolute;
  right: -0.5rem;
  top: 50%;
  z-index: 90;
}

.vfra-top-reviewers-container {
  display: -webkit-inline-box;
  overflow: auto;
  padding-bottom: 1rem;
  scrollbar-width: none;
}

.vfra-top-reviewers-container-before::before {
  content: "";
  width: 3rem;
  height: 60%;
  left: 0px;
  position: absolute;
  background: linear-gradient(90deg, rgb(255, 255, 255), rgba(255, 255, 255, 0.05));
}

.vfra-top-reviewers-container::after {
  content: "";
  width: 3rem;
  height: 60%;
  right: 0px;
  position: absolute;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.05), rgb(255, 255, 255));
}
