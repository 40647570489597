/* .ant-popover {
  width: 66% !important;
} */

.vfra-perfume-item {
  position: relative;
  border: 1px solid #f0f0f0;
  border-radius: 6px;
  padding: 6px;
}

.vfra-perfume-item:hover {
  cursor: pointer;
  border-color: #1677ff;
  background-color: #f5f5f5fe;
}

.active {
  border-color: #1677ff;
}

.vfra-perfume-item-active-icon {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  transform: translate(50%, -50%);
  color: #1677ff;
  display: flex;
  background-color: white;
}

@media (max-width: 768px) {
  .ant-popover {
    width: 80% !important;
  }
}
