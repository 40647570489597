.vfra-content-home {
  min-height: 100vh;
  background-color: var(--white-color);
}

@media (max-width: 768px) {
  .vfra-content-home {
    padding: 0 1rem;
  }
}

@media (min-width: 768px) {
  .vfra-content-home {
    padding: 0 1.5rem;
    /* padding-top: 3rem; */
    padding-top: 8vh;
  }
}

@media (min-width: 890px) {
  .vfra-content-home {
    padding: 0 1.5rem;
    /* padding-top: 3.5rem; */
    padding-top: 8vh;
  }
}

@media (min-width: 1681px) {
  .vfra-content-home {
    padding: 0 10vw;
    padding-top: 8vh;
  }
}

@media (min-width: 2500px) {
  .vfra-content-home {
    padding: 0 20vw;
    padding-top: 8vh;
  }
}
