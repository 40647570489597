.vfra-basic-content {
  min-height: 100vh;
  background-color: var(--white-color);
}

@media (max-width: 768px) {
  .vfra-basic-content {
    padding: 0 1rem;
  }
}

@media (min-width: 768px) {
  .vfra-basic-content {
    padding: 0 1.5rem;
  }
}

@media (min-width: 1681px) {
  .vfra-basic-content {
    padding: 0 10vw;
  }
}

@media (min-width: 2500px) {
  .vfra-basic-content {
    padding: 0 20vw;
  }
}
